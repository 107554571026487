import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { createRoot } from 'react-dom/client';

import Router from "./router";
import i18n from "./translation";

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

// Render the App component
root.render(<App />);

